import axios from 'axios'
import React ,{useState}from 'react'

export default function ForgotPassword(props) {
    console.log("propspropspropspropsprops",props)

    const [email, setEmail] = useState("");
    const [error,setSetError]=useState("");
 
    const [loginStatusCode, setLoginStatusCode] = useState('');
  
  
   
    const [loginEmail, setLoginEmail] = useState("");
    const [otp, submitOtp] = useState("");
const [fillPassword,setFillPassword]=useState("")
const [showFillPassword,setShowFillPassword]=useState(false)
const [optError,setOtpError]=useState("")
    
  const [sumbitOtp, setSumbitOtp] = useState(false)

    const [showConfPass, setConfPassShow] = useState('');
    const [password, setPassword] = useState("");
    const [cnfPassword, setCnfPassword] = useState("");
const [otpEnter, setOtpEnter] = useState("")

    const passwordError =
    "Must contain at least one number, one uppercase and lowercase letter, and Min 8 or more characters";
  const cnfPassError = "Password did not match";
  const emailError = "Enter valid Email Id ";
  const nameError = "Enter Vaild Name";
const handleRegistration=async()=>{
    let response=await axios.post('http://localhost:3002/submitotp',{
    
          userEmail:loginEmail,
      otpTemp:otpEnter,
      
    })
    if(response.status==200){

        setShowFillPassword(true)
        setSumbitOtp(false)
      
      }
      else{
         setOtpError(response.data.data)
      }
  


    }
    
    const handleLogin=async()=>{
    
      let response=await axios.post('http://localhost:3002/forgot',{
     
          userEmail: loginEmail,
          // password:loginPass,

         
         
    }
  
    )
    console.log('ssss',response)

    if(response.status==200){

      setSumbitOtp(true)

    
    }
    else{
      setSetError(response.data.data)
    }
    
}
    var passwordCheck =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  var reEmail =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    return (
        <div className="w-95 flex">
         
        <div className="flex w-75"></div>
        <div className="w-25 mt3">
          <div className="flex w-100 fw6  mt3">Reset Password</div>
          {sumbitOtp &&  !showFillPassword&& <div className="flex w-100 flex-column">
            <div className="flex flex-column  w-100 mt1 ">
              <div className="flex flex-column w-100">
                <input
                  onChange={(e) => setOtpEnter(e.target.value)}
                  className="w-100 mt2 pa1 "
                  type="text"
                  value={otpEnter}
                  placeholder="Enter 4 digit OTP  "
                />
                <p className="red fw6 f5 lh-copy">
                  {(otpEnter.length=="" || otpEnter.length==4) ? "" : "Enter 4 digit otp"}
                </p>
              </div>
            
              

              <div className="w-100 flex items-center justify-center">
                <button
                  onClick={() =>
                    (otpEnter.length==4)  ? handleRegistration(): null
                  }
                  style={{
                    opacity:
                    (otpEnter.length==4) ? 1 : 0.6,
                  }}
                  className="w-70 pa2 br2 bg-green mt2  borderZero white pointer"
                >
                 11Submit
                </button>
              
              </div>
              {optError==""?null: <div className="red_10 mt3 fw6 w-100 tc">{optError}</div>}
              <div className="mt2  fw6 flex items-center justify-center red">{loginStatusCode==202&&"Password is wrong"} {loginStatusCode==203&&"Email Address not found"}</div>
            </div>
          </div>}


          {!sumbitOtp&&  !showFillPassword&&
            <div className="flex w-100 flex-column">
            <div className="flex flex-column  w-100 mt1 ">
              <div className="flex flex-column w-100">
                <input
                  onChange={(e) => (setLoginEmail(e.target.value),setSetError(""))}
                  className="w-100 mt2 pa1 "
                  type="text"
                  value={loginEmail}
                  placeholder="Enter Email id "
                />
                <p className="red fw6 f5 lh-copy">
                  {(reEmail.test(loginEmail) || loginEmail=="") ? "" : emailError}
                </p>
              </div>
            
              

              <div className="w-100 flex items-center justify-center">
                <button
                  onClick={() =>
                    (loginEmail != "" && reEmail.test(loginEmail)) ? handleLogin(): null
                  }
                  style={{
                    opacity:
                    (loginEmail != "" && reEmail.test(loginEmail)) ? 1 : 0.6,
                  }}
                  className="w-70 pa2 br2 bg-navy mt2  borderZero white pointer"
                >
                  Submit
                </button>
              
              </div>
              {error==""?null: <div className="flex w-100 fw5 items-center justify-center mt2 red">{error}</div>}
              <div className="mt2  fw6 flex items-center justify-center red">{loginStatusCode==202&&"Password is wrong"} {loginStatusCode==203&&"Email Address not found"}</div>
            </div>
          </div>
          }


{/* enter new password */}
          {showFillPassword &&
          <div className="flex w-100 ">

<div className="flex flex-column w-100">

    
                <input
                  onChange={(e) => (setFillPassword(e.target.value))}
                  className="w-100 mt2 pa1 "
                  type="text"
                  value={fillPassword}
                  placeholder="Enter New  Password"
                />
                <p className="red fw6 f5 lh-copy">
                  {(reEmail.test(fillPassword) || fillPassword=="") ? "" : emailError}
                </p>
              </div>
          </div>

          }

        </div>
      </div>
    )

}
