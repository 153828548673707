import { useEffect, useState } from "react";
import "./App.css";

// import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// import Zone1 from "./common/zone1";
import axios from "axios";
//  import JllEntryLobby from "./jlle.mp4";
//  import LobbyMaiVideo from "./jllm.mp4";

// import { useEffect, useState } from 'react';
// import { useState } from 'react';
// import Expo from "./common/stall";
import "./tachyons/css/tachyons.min.css";
// import '../tachyons/css/swiper.min.css';
import "./tachyons/css/style.css";
// import Apicall from "./common/apicall";
// import Header from "./common/header";
// import Footer from "./common/footer";
import Homepage from "./pages/homepage";

import { useHistory } from "react-router-dom";
import Live from "./pages/live";
// import { useHistory } from 'react-router-dom';

// import { useLocation } from "react-router-dom";
// import Phtotobooth  from "./pages/phtotobooth";

function App1() {
  return (
    <iframe
      className="vide "
      src="https://player.vimeo.com/video/522670331?autoplay=1&mute=0"
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="video"
    />
  );
}

const Submenu = () => {
  return (
    <ul className="nav__submenu">
      <li className="nav__submenu-item ">
        <a>Our Company</a>
      </li>
      <li className="nav__submenu-item ">
        <a>Our Team</a>
      </li>
      <li className="nav__submenu-item ">
        <a>Our Portfolio</a>
      </li>
    </ul>
  );
};

export default function App(props) {
  const [dt, setDt] = useState(0);
  const [notiNo, setNotiNo] = useState(localStorage.getItem("notino"));

  const endpoint = "https://sirc.virtuallive.in:3002";

  //  useEffect(async() => {

  //   let timer1 = setTimeout(async() => {
  //     let response = await axios.get('https://sirc.virtuallive.in:3002/notificationinfo')
  //  console.log(response.data)

  //   if(response&&response.data&&response.data.status=="true")
  //   {

  //     setNotification(response.data.content)
  //     setNotiNo(response.data.notino)
  //     let timer2 = setTimeout(async() => {

  //       localStorage.setItem('notino',response.data.notino)
  //       setNotification(false)
  //       clearTimeout(timer2);
  //     },10000)

  //   }
  //   setDt(dt+1)
  //   }, 20000);

  //   return () => {
  //    clearTimeout(timer1);
  //   };
  // },

  // [dt]
  // )

  const [popUpName, setPopDown] = useState(true);
  const [notification, setNotification] = useState(true);

  // http://localhost:3001/notificationinfo

  const [stateMenu, setstateMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  // const location = useLocation();
  // console.log("===>>>>>>", window.location.pathname);
  return (
    <Router>
      <div className="flex items-center justify-center w-100  ">
        {showProfile && (
          <div
            className="flex w-100 ba    justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-30   flex justify-center items-center mh3 br3 overflow-hidden">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex pa3 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex items-end justify-end pointer "
                    onClick={() => setShowProfile(false)}
                  >
                    X
                  </div>
                  <div className=" flex items-center justify-center ">
                    {" "}
                    <img
                      className="h33"
                      // src={require("../src/image/prof.png").default}
                      alt=""
                    />
                  </div>
                  <div className=" flex items-center justify-center mv2 fw6  ">
                    {" "}
                    Name :{" "}
                    <span className="fw4">
                      {localStorage.getItem("userName")}
                    </span>
                  </div>
                  <div className=" flex items-center justify-center mv2 fw6  ">
                    {" "}
                    Email :{" "}
                    <span className="fw4">
                      {localStorage.getItem("userEmail")}
                    </span>
                  </div>
                  <div className=" flex items-center justify-center mt2 fw5 pointer bg-red h2 w-50 br-pill  mt2 ">
                    {" "}
                    <span className=" white f6">Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {notification &&
        window.location.pathname !== "/" &&
        localStorage.getItem("notino") !== notiNo && (
          <div className="flex z-max items-center justify-center w-100   ">
            <div
              className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
              style={{ backdropFilter: "blur(2px)" }}
            >
              <div className=" w-40    flex justify-center items-center mh3 br3 ">
                <div className="w-100  flex  items-center justify-center bg-white">
                  <div className="flex welcomeMsj pa3 flex-column items-center justify-center w-100 ">
                    <div
                      className=" w-100 flex white items-end justify-end pointer "
                      onClick={() => setNotification(false)}
                    >
                      X
                    </div>
                    <div
                      className="  w-100 flex items-center justify-center "
                      style={{ overflow: "scroll" }}
                    >
                      <div className="white  tc">{notification}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {window.location.pathname !== "/"
        ? localStorage.getItem("userEmail") == null
          ? (window.location.href = "/")
          : ""
        : ""}

      <Switch>
        {true && (
          <Route exact path="/">
            <Homepage />
          </Route>
        )}

        <Route exact path="/live">
          <Live />
        </Route>
        {/* <Route exact path="/resource-center">
          <Reac />
        </Route> */}
      </Switch>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
