import React, { useEffect, useState } from "react";
import axios from "axios";

import Giff from "../image/mob.gif";
export default function Live() {



  const debounce = (fn, delay) => {
    let timeoutID;
    return function (...args) {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      timeoutID = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const [iner, setIner] = useState(0);
  const [out, setOut] = useState(0);

  const [levl, setLevl] = useState(0);
  const [counter, setCounter] = useState(
    window.innerHeight > window.innerWidth ? "landscape" : "oriential"
  );
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };


  useEffect(async() => {
  
    // let response = await axios.post(`https://jllpartnersummit2021.virtuallive.in:3002/timespentrecord`, {userEmail: localStorage.getItem('userEmail'),pageid:"lobby",time:10})

   let response2 = await axios.post('https://larsentoubro.virtuallive.in:3002/lastlogin',
   {userEmail: localStorage.getItem('userEmail'),
   userName: localStorage.getItem('userName'),
 
  
 })
},[])

  const [land, setLand] = useState(deviceType());

  // if(window.innerHeight > window.innerWidth){
  //   alert("Please use Landscape!");
  // }

  var chatMock = [
    {
      level: 1,
      name: "John Doe",
      text: "Chat text gors here",
      next: null,
    },
    {
      level: 1,
      name: "John Doe",
      text: "Chat text gors here",
      next: [
        {
          level: 2,
          name: "John Doe",
          text: "Chat text gors here",
          next: [
            {
              level: 3,
              name: "John Doe",
              text: "Chat text gors here",
              next: null,
            },
            {
              level: 3,
              name: "John Doe",
              text: "Chat text gors here",
              next: null,
            },
          ],
        },
        {
          level: 2,
          name: "John Doe",
          text: "Chat text gors here",
          next: null,
        },
      ],
    },
  ];

  let list = {
    value: 1,
    next: { value: 2, next: { value: 3, next: { value: 4, next: null } } },
  };
  const array = [
    {
      id: 1,
      name: "bla",
      children: [
        {
          id: 23,
          name: "bla",
          children: [
            { id: 88, name: "bla" },
            { id: 99, name: "bla" },
          ],
        },
        { id: 43, name: "bla" },
        {
          id: 45,
          name: "bla",
          children: [
            { id: 43, name: "bla" },
            { id: 46, name: "bla" },
          ],
        },
      ],
    },
    {
      id: 12,
      name: "bla",
      children: [
        {
          id: 232,
          name: "bla",
          children: [
            { id: 848, name: "bla" },
            { id: 959, name: "bla" },
          ],
        },
        { id: 433, name: "bla" },
        {
          id: 445,
          name: "bla",
          children: [
            { id: 443, name: "bla" },
            {
              id: 456,
              name: "bla",
              children: [
                { id: 97, name: "bla" },
                { id: 56, name: "bla" },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 15,
      name: "bla",
      children: [
        {
          id: 263,
          name: "bla",
          children: [
            { id: 868, name: "bla" },
            { id: 979, name: "bla" },
          ],
        },
        { id: 483, name: "bla" },
        {
          id: 445,
          name: "bla",
          children: [
            { id: 423, name: "bla" },
            { id: 436, name: "bla" },
          ],
        },
      ],
    },
  ];

  const findItemNested = (arr, itemId, nestingKey) =>
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.id === itemId) return item;
      if (item[nestingKey])
        return findItemNested(item[nestingKey], itemId, nestingKey);
    }, null);
  const res = findItemNested(array, 9519, "children");
  console.log("67890", res);

  const arr = [
    {
      title: "some title",
      channel_id: "123we",
      options: [
        {
          channel_id: "abc",
          image: "http://asdasd.com/all-inclusive-block-img.jpg",
          title: "All-Inclusive",
          options: [
            {
              channel_id: "dsa2",
              title: "Some Recommends",
              options: [
                {
                  image: "http://www.asdasd.com",
                  title: "Sandals",
                  id: 1,
                  content: {
                    //...
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const customFilter = (chatMock2) => {
    console.log("ssss=>>>>>", chatMock2);
    if (chatMock2.next) {
      return chatMock2.next;
    } else {
      return "not";
    }
  };


  useEffect(() => {
    const script = document.createElement("script");

    script.src = 'https://embed.tawk.to/5f521365f0e7167d000d67fc/default';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // alert(6)
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(async () => {
      setCounter(
        window.innerHeight > window.innerWidth ? "landscape" : "oriential"
      );
      setOut(out + 1);
      // alert(8)
    }, 1000);
    return () => clearTimeout(timer);
  }, [out]);

const [out1,setout1]=useState(0)
  useEffect(() => {
    const timer1 = setTimeout(async () => {
      
      setout1(out1 + 1);
      let response2 = await axios.post('https://larsentoubro.virtuallive.in:3002/timespentrecord',
      {userEmail: localStorage.getItem('userEmail'),
      pageid: 'live',
      time:1
    
     
    })
      // alert(8)
    }, 60000);
    return () => clearTimeout(timer1);
  }, [out1]);



  return (
    <>
      {land !== "mobile" ? (
        <div className="  flex   bg-white " style={{ height: "100vh", width: "100%" }}>
          <div className="w-100 h-100  ">
          <iframe src="https://vimeo.com/event/1685631/embed"
           frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
           allowfullscreen></iframe>
          </div>
        </div>
      ) : (
        <div>
          {counter == "landscape" ? (
            <div className=" pa2 flex flex-column w-100 min-vh-80 items-center justify-center tc">
              <p>
                {" "}
                <img src={Giff} className="h4" alt="" srcset="" />
              </p>
              <p className="mv2">Please Rotate your device</p>
              <p>
                For a better experience, please view on a desktop/laptop screen.
                <br />
                Some functionalities might not work properly in mobile devices.
              </p>
            </div>
          ) : (
            <div className="  flex bg-white  " style={{ height: "100vh", width: "100%" }}>
          <div className="w-100 h-100  ">
          <iframe src="https://vimeo.com/event/1685631/embed"
           frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
           allowfullscreen></iframe>
          </div>
        </div>
          )}
        </div>
      )}
    </>
  );
}

// const call=(mapval)=>{
//   {mapval.map((item)=>{ return <div  className="flex flex-column" >

//   <div className="flex ma2 bg-navy white">{item.text}
//     <div className="mh2 pa2 bg-green" onClick={()=>setIner(iner+1)}>in</div>
//     <div className="mh2  pa2 bg-red"  onClick={()=>setIner(iner-1)}>out</div></div>

//   </div>

//   })}
// }
